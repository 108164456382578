<template>
  <div id="todo-app" class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">
    <!--sidebar-->
    <vs-sidebar class="items-no-padding" parent="#todo-app" :click-not-close="sidebar.clickNotClose" :hidden-background="sidebar.clickNotClose" v-model="sidebar.active">
      <component :is="scrollbarTag" class="todo-scroll-area" :settings="{ maxScrollbarLength: 60, wheelSpeed: 0.30 }">
        <div class="px-6">
          <div class="vx-card vx-col w-full mb-2 shadow-none">
            <label class="ml-1 text-xs">Proyek</label>
            <vs-select autocomplete v-model="sidebar.proyek.selected" class="w-full">
              <vs-select-item v-for="(it, index) in sidebar.proyek.items" :key="index" :value="it" :text="it.nama"/>
            </vs-select>
<!--            <label class="ml-1 text-xs">Jenis</label>-->
<!--            <vs-select autocomplete v-model="sidebar.jenis_item_group1" class="w-full">-->
<!--              <vs-select-item v-for="(it, index) in listJenisItemGroup1" :key="index" :value="it.id" :text="it.nama"/>-->
<!--            </vs-select>-->
          </div>
<!--          <div v-if="sidebar.loading && !this.isGroup1NonUnit" class="animate-pulse">-->
<!--            <h6>Loading...</h6>-->
<!--            <vs-progress indeterminate color="primary" :height="1"/>-->
<!--          </div>-->
<!--          <template v-if="!sidebar.loading && !this.isGroup1NonUnit">-->
<!--            <label class="ml-1 text-xs">Tipe</label>-->
<!--            <p v-if="isSidebarEmpty" class="text-xs mt-3 ml-1 opacity-50">Tidak ada data.</p>-->
<!--            <span class="flex mt-3 cursor-pointer" v-for="(item, index) in sidebar.propertiTipe.items" :key="index" :class="{'text-primary font-semibold': item === sidebar.propertiTipe.selected}" @click="onSidebarSelected(item)">-->
<!--              <feather-icon icon="ChevronRightIcon" :svgClasses="[{'text-primary stroke-current': item === sidebar.propertiTipe.selected}, 'heads-4 w-4']"></feather-icon>-->
<!--              <span class="text-sm ml-2">{{ item.nama }}</span>-->
<!--            </span>-->
<!--          </template>-->
        </div>
      </component>
    </vs-sidebar>

    <!--box-->
    <div :class="{'sidebar-spacer': sidebar.clickNotClose}" class="no-scroll-content border border-r-0 border-b-0 border-t-0 border-solid d-theme-border-grey-light no-scroll-content">
      <div class="flex d-theme-dark-bg items-center border border-l-0 border-r-0 border-t-0 border-solid d-theme-border-grey-light">
        <!--toggle sidebar-->
        <feather-icon class="md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer" icon="MenuIcon" @click.stop="toggleSidebar(true)"/>

        <!--search -->
        <div class="w-full flex">
          <vs-checkbox class="justify-center w-1/12 mr-12" size="small" @change="toggleCheckAll($event.target.checked, item.id)">ALL</vs-checkbox>
          <vs-input icon-no-border size="large" icon-pack="feather" icon="icon-search" placeholder="Cari" v-model="search" class="vs-input-no-border vs-input-no-shdow-focus w-6/12 sm:w-10/12"/>
          <vs-button @click="save" color="primary" type="filled" class="w-5/12 sm:w-2/12 rounded-none" :disabled="!box.unsaved || box.saving">
            <span v-if="box.saving" class="animate-pulse">Menyimpan...</span>
            <span v-if="!box.saving">Simpan</span>
          </vs-button>
        </div>
      </div>

      <!--main content -->
      <component :is="scrollbarTag" class="todo-content-scroll-area" :settings="{ maxScrollbarLength: 60, wheelSpeed: 0.30 }">
        <div class="w-full" :class="{'px-2 pt-4': box.errors !== null}"><ValidationErrors :errors="box.errors"/></div>
        <h6 v-if="box.loading"  class="p-10 text-center animate-pulse">Loading...</h6>
        <p v-if="isBoxEmpty" class="p-10 text-center">Tidak ada data.</p>

        <div class="w-full p-2" v-if="!box.loading && !isBoxEmpty">
          <!--------CHECKED-------->
          <div>
            <vx-card v-for="item in boxItemsChecked" :key="item.id" class="rounded-none mb-2 border border-primary border-solid">
              <div class="vx-row">
                <div class="vx-col w-4/6 flex sm:items-center sm:flex-row flex-col">
                  <div class="flex items-start">
                    <vs-checkbox class="w-8 m-0 mt-0.75 vs-checkbox-small" :checked="true" @change="onUnchecked(item.id)"/>
                    <h5 class="todo-title">
                      <span class="primary">{{ item.kode_coa }}</span>
                      ({{ item.nama_coa }})
                    </h5>
                  </div>
                </div>
<!--                <div class="vx-col w-2/6 ml-auto flex sm:justify-end">-->
<!--                  <feather-icon-->
<!--                    :icon="item.showEdit ? 'CheckIcon' : 'EditIcon'"-->
<!--                    class="cursor-pointer"-->
<!--                    svgClasses="text-warning stroke-current w-5 h-5"-->
<!--                    @click="item.showEdit = !item.showEdit"/>-->
<!--                </div>-->
              </div>
              <br>
              <div class="vx-row mb-3">
                <div class="vx-col sm:w-1/2 w-full">
                  <vs-input class="w-full" type="number" label-placeholder="Saldo Debet" v-model="item.debet" @change="box.unsaved = true"/>
                </div>
                <div class="vx-col sm:w-1/2 w-full">
                  <vs-input class="w-full" type="number" label-placeholder="Saldo Kredit" v-model="item.kredit" @change="box.unsaved = true"/>
                </div>
              </div>
<!--              <div class="vx-row">-->
<!--                <div class="vx-col w-full px-12">-->
<!--                  <p class="mt-2 truncate">Bobot: {{ item.bobot }}%, Tahapan: {{ item.tahapan }}</p>-->
<!--                </div>-->
<!--              </div>-->
              <div v-if="item.showEdit">
                <vs-divider color="warning">Edit Area</vs-divider>
<!--                <div class="vx-row mb-3">-->
<!--                  <div class="vx-col sm:w-1/2 w-full">-->
<!--                    <vs-input class="w-full" type="number" label-placeholder="Saldo Debet" v-model="item.debet" @change="box.unsaved = true"/>-->
<!--                  </div>-->
<!--                  <div class="vx-col sm:w-1/2 w-full">-->
<!--                    <vs-input class="w-full" type="text" label-placeholder="Saldo Kredit" v-model="item.kredit" @change="box.unsaved = true"/>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
            </vx-card>
          </div>

          <!--------UNCHECKED-------->
          <div>
            <div v-for="item in boxItemsUnchecked" :key="item.id" class="p-6 vx-card rounded-none mb-2 bg-opacity-25">
              <div class="vx-row">
                <div class="vx-col w-5/6 flex sm:items-center sm:flex-row flex-col">
                  <div class="flex items-start">
                    <vs-checkbox class="w-8 m-0 mt-0.75 vs-checkbox-small" :checked="false" @change="onChecked(item.id)"/>
                    <h5 class="todo-title">{{ item.nama }}
                      <span class="text-primary">({{ item.kode }})</span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </component>

    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Draggable from 'vuedraggable'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import CoaRepository from '@/repositories/master/coa-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import vSelect from 'vue-select'

export default {
  name: 'CoaSetProyek',
  components: {
    ValidationErrors,
    VuePerfectScrollbar,
    Draggable,
    'v-select': vSelect
  },
  mounted () {
    this.getAllProyek()
  },
  created () {
    this.setSidebarWidth()
  },
  data () {
    return {
      search: '',
      sidebar: {
        clickNotClose: true,
        active: true,
        loading: false,
        proyek: {
          items: [],
          selected: {}
        }
      },
      box: {
        errors: null,
        loading: false,
        saving: false,
        unsaved: false,
        items: [],
        checked: []
      }
    }
  },
  watch: {
    'sidebar.proyek.selected' () {
      this.refreshDataBox()
    },
    windowWidth () {
      this.setSidebarWidth()
    }
  },
  computed: {
    boxItemsChecked () {
      if (this.search !== '') {
        const search = this.search.toLowerCase()
        return _.filter(this.box.checked, (item) => {
          return item.nama_coa.toLowerCase().includes(search) || item.kode_coa.toLowerCase().includes(search)
        })
      }
      return this.box.checked
    },
    boxItemsUnchecked () {
      const uncheckedItems = _.reject(this.box.items, (item) => _.find(this.box.checked, { id_coa: item.id }))
      if (this.search !== '') {
        const search = this.search.toLowerCase()
        return _.filter(uncheckedItems, (item) => {
          return item.nama.toLowerCase().includes(search) || item.kode.toLowerCase().includes(search)
        })
      }
      return uncheckedItems
    },
    isBoxEmpty () {
      return (!this.box.loading && this.box.items.length < 1 && this.box.checked.length < 1)
    },
    isUnsavedChanges () {
      return this.box.unsaved
    },
    scrollbarTag () {
      return this.$store.getters['theme/scrollbarTag']
    },
    windowWidth () {
      return this.$store.state.theme.windowWidth
    }
  },
  methods: {
    onChecked (id) {
      const boxItem = _.find(this.box.items, { id: id })
      const newItemChecked = {
        id: uuid(),
        id_coa: boxItem.id,
        kode_coa: boxItem.kode,
        debet: 0,
        kredit: 0,
        nama_coa: boxItem.nama,
        showEdit: false
      }

      setTimeout(() => {
        this.box.checked.push(newItemChecked)
        this.box.unsaved = true
        this.search = ''
      }, 250)
    },

    onUnchecked (id) {
      setTimeout(() => {
        const index = _.findIndex(this.box.checked, item => item.id === id)
        this.box.checked.splice(index, 1)
        this.box.unsaved = true
        this.search = ''
      }, 250)
    },

    getDataBox () {
      this.box.loading = true

      const params = {}
      params.filter = JSON.stringify({
        tipe: 'DETAIL'
      })

      CoaRepository.all(params)
        .then(response => {
          this.box.items = response.data.data
          this.getDataBoxChecked()
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    getDataBoxChecked () {
      this.box.loading = true

      // create parameters object
      const filter = {
        id_proyek: this.sidebar.proyek.selected.id,
        tipe: 'DETAIL'
      }
      const params = {}
      params.filter = JSON.stringify(filter)

      // request
      CoaRepository.getD(params)
        .then(response => {
          const additionalKeys = { showEdit: false }
          this.box.checked = _.map(response.data.data, function (item) {
            return _.extend({}, item, additionalKeys)
          })
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.box.loading = false
        })
    },

    refreshDataBox () {
      this.clearBox()
      this.getDataBox()
      this.box.unsaved = false
    },

    clearBox () {
      this.box.items = []
      this.box.checked = []
    },

    getAllProyek () {
      this.sidebar.loading = true

      ProyekRepository.getAll()
        .then(response => {
          this.sidebar.proyek.items = response.data.data
          if (this.sidebar.proyek.items.length > 0) {
            this.sidebar.proyek.selected = this.sidebar.proyek.items[0]
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.sidebar.loading = false
        })
    },

    save () {
      this.box.errors = null
      this.box.saving = true
      const params = {
        id_proyek: this.sidebar.proyek.selected.id,
        items: this.box.checked
      }
      CoaRepository.saveD(params)
        .then(response => {
          this.box.unsaved = false
          this.getDataBoxChecked()
          this.notifySuccess('Data berhasil disimpan.')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.box.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.box.saving = false
        })
    },

    onSidebarSelected (item) {
      // confirm unsaved
      if (this.isUnsavedChanges) {
        this.$vs.dialog({
          ...this.confirmLeaveOptions(),
          accept: () => {
            this.box.unsaved = false
            this.onSidebarSelected(item)
          }
        })
        return
      }

      this.sidebar.propertiTipe.selected = item
    },

    confirmLeaveOptions () {
      return {
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Ada perubahan yang belum disimpan, yakin ingin berpindah halaman?',
        acceptText: 'Yakin',
        cancelText: 'Batal'
      }
    },

    setSidebarWidth () {
      if (this.windowWidth < 992) {
        this.sidebar.active = this.sidebar.clickNotClose = false
      } else {
        this.sidebar.active = this.sidebar.clickNotClose = true
      }
    },

    toggleSidebar (value = false) {
      if (!value && this.sidebar.clickNotClose) return
      this.sidebar.active = value
    }

  },
  beforeRouteLeave (to, from, next) {
    if (this.box.unsaved) {
      this.$vs.dialog({
        ...this.confirmLeaveOptions(),
        accept: () => {
          next()
        }
      })
      return
    }
    next()
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/vuexy/apps/todo.scss";
</style>
